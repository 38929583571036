.hi-visitors {
	background-image: url("/_files/visitors/images/hero-visitors-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("/_files/visitors/images/hero-visitors.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("/_files/visitors/images/hero-visitors-2x.jpg");
	}
}
