/*!
 * Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */

// Include only what you need at the end of project
@import "./node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "./node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "./node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "./node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "./node_modules/@fortawesome/fontawesome-pro/scss/sharp-solid";
@import "./node_modules/@fortawesome/fontawesome-pro/scss/shims";
// @import "./node_modules/@fortawesome/fontawesome-pro/scss/sharp-regular";
// @import "./node_modules/@fortawesome/fontawesome-pro/scss/sharp-solid";
// @import "./node_modules/@fortawesome/fontawesome-pro/scss/duotone";
// @import "./node_modules/@fortawesome/fontawesome-pro/scss/light";

//FA Overrides
