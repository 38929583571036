.hi-about {
	background-image: url("/_files/about/images/hero-about-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("/_files/about/images/hero-about.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("/_files/about/images/hero-about-2x.jpg");
	}
}

.hi-about-faculty-research {
	background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-faculty-research-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-faculty-research.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-faculty-research-2x.jpg");
	}
}

.hi-about-traditions {
	background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-traditions-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-traditions.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/hero/hero-traditions-2x.jpg");
	}
}
