.skip-to-content {
	a {
		overflow: hidden;
		left: -100000px;
		position: absolute;
		height: 0;
		width: 0;

		&:focus,
		&:focus-within {
			left: auto;
			position: relative;
			height: auto;
			width: auto;
		}
	}
}
