.global-header {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.top-bar {
	background-color: $utsa-blue;
	color: $white;
	padding: 0 1.5rem;
	height: auto;
	border-bottom: 4px solid $utsa-orange;
	width: 100%;
	align-content: center;
	font-size: rem-calc(13);

	.col-4 {
		line-height: 1;

		a {
			padding-top: rem-calc(3);
		}
	}

	.row {
		height: 2.5rem;
	}

	ul {
		margin: 0;
		padding: 0;
		text-align: right;
		justify-content: flex-end;
		display: flex;
		gap: 1rem;
		white-space: nowrap;

		li {
			margin: 0;
			display: inline-block;
			border-left: 1px solid rgba($white, 0.5);
			padding-left: 1rem;
			line-height: 1;

			&:first-child {
				border-left-width: 0;
				padding-left: 0;
			}

			a {
				color: $white;
				display: block;
				padding: 0;
				font-size: 0.875rem;
				font-weight: $normal;
				text-decoration: none;
				border-bottom: 1px solid transparent;
				outline: none;
				transition: border-color 0.3s ease;

				&:hover {
					color: $gray-01;
					background-color: transparent;
					border-color: $white;
					text-decoration: none;
				}

				&:focus {
					text-decoration: none;
					background-color: transparent;
					border-color: $utsa-orange;
				}

				&.global-search {
					text-decoration: none;

					&:focus {
						text-decoration: none;
						border-color: $utsa-orange;
					}
				}
			}
		}
	}

	.tb-logo {
		display: inline-block;
		&:hover,
		&:focus {
			background-color: $blue-02;
		}

		img {
			height: rem-calc(20);
			width: auto;
		}
	}
}

.site-nav {
	background-color: $white;
	transition: all 1s ease-out;
	width: 100%;
	padding: 1.25rem 0 0;
	box-shadow: $nav-shadow;

	@include media-breakpoint-down(lg) {
		padding: 1rem 0;
	}

	.menu-col {
		line-height: 0;
	}

	.utsa-logo,
	.site-logo {
		display: inline-block;
		outline: none;
		border-bottom: 1px solid transparent;

		img {
			max-height: 30px;
			width: auto;
			max-width: 100%;

			@include media-breakpoint-down(lg) {
				max-height: 35px;
			}
		}

		&:focus {
			border-color: $orange-a11y;
		}
	}

	// Primary Aligned
	&.primary-aligned {
		&.site-nav {
			padding: 0;

			@include media-breakpoint-down(lg) {
				padding: 1rem 0;
			}

			.navbar {
				padding: 0;

				.collapse {
					justify-content: end;
				}

				.navbar-nav {
					.nav-item {
						a {
							&.nav-link {
								padding: 1.75rem 0.625rem 1.5rem;
							}
						}
					}
				}
			}
		}
	}

	&.primary-aligned,
	&.primary-stacked {
		.quick-links-bar {
			position: relative;

			.qlb-container {
				position: absolute;
				z-index: 999;
				top: 0;
				right: 0;

				nav {
					&.qlb-nav {
						ul {
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							list-style: none;
							padding-left: 0;

							&:after {
								display: inline;
								background-color: #d3430d;
								content: "";
								width: 2.5rem;
							}

							li {
								&:first-child {
									a {
										clip-path: polygon(0 0, 100% 0, 100% 100%, 45px 100%);
										padding-left: 3.5rem;
										margin-right: -1px;
									}
								}

								a {
									display: flex;
									background-color: #d3430d;
									color: #fff;
									text-decoration: none;
									padding: 1rem 1.125rem;
									font-weight: 600;

									&:hover,
									&:focus {
										background-color: $utsa-blue;
										outline: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.nav-toggle {
	text-decoration: none;
	padding: 0;
	display: inline-block;
	line-height: 1;
	border: none;

	.bar {
		height: rem-calc(4);
		width: rem-calc(42);
		display: block;
		background-color: $utsa-orange;
		margin-top: 6px;

		&.bar-top {
			margin-top: rem-calc(3);
		}
	}

	&:hover,
	&:focus {
		outline: none;
		border: none;
		.bar {
			background-color: $utsa-blue;
		}
	}
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	box-shadow: $shadow;
}

.mm-menu {
	.quick-links-bar-mobile {
		background-color: $orange-a11y;
		color: $white;

		.mm-listitem__text {
			color: $white;

			&:hover {
				background-color: $utsa-blue;
			}
		}
	}
}

.mm-logo > a.mobile-logo {
	justify-content: start;
	margin-left: 1.25rem;
	border: none;
}
