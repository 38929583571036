.bg-blue {
	background-color: $utsa-blue;
	color: $white;

	&-09 {
		background-color: rgba($utsa-blue, 0.9);
	}

	&-08 {
		background-color: rgba($utsa-blue, 0.8);
	}

	&-07 {
		background-color: rgba($utsa-blue, 0.7);
	}

	&-06 {
		background-color: rgba($utsa-blue, 0.6);
	}

	&-05 {
		background-color: rgba($utsa-blue, 0.5);
	}

	p {
		color: $white;
	}

	@include headings {
		color: $white;
		&.special-heading {
			color: $white;

			.kicker {
				color: $white;
			}
		}
	}
}

.bg-gray-00 {
	background-color: $gray-00;
}

.bg-rowdy {
	background-image: url("/_files/wss/v1.0/images/backgrounds/bg-rowdy.svg");
	background-size: 40%;
	background-repeat: no-repeat;
	background-position: right 8rem;

	@include media-breakpoint-down(md) {
		background-image: none;
	}
}

.bg-chevron {
	position: relative;

	&.bg-chevron-left {
		&:after {
			background-position: left 2rem;
		}
	}

	&.bg-chevron-right {
		&:after {
			background-position: right 2rem;
		}
	}

	&:after {
		content: "";
		height: 100%;
		width: 100%;
		background-image: url("/_files/wss/v1.0/images/backgrounds/bg-chevron.svg");
		background-size: 25%;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.05;
		z-index: -1;

		// @include media-breakpoint-up(md) {
		// 	background-size: 25%;
		// }
	}

	&.bg-chevron-light {
		&:after {
			z-index: -1;
			background-image: url("/_files/wss/v1.0/images/backgrounds/bg-chevron-light.svg");
		}
	}
}

.background-block {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&.bb-overlay {
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba($utsa-blue, 0.75);
			z-index: 1;
		}

		.container {
			position: relative;
			z-index: 2;
		}
	}

	&.bb-san-antonio {
		background-image: url("../images/backgrounds/san-antonio-bg.jpg");

		@include media-breakpoint-up(lg) {
			background-image: url("../images/backgrounds/san-antonio-bg-2x.jpg");
		}
	}

	&.bb-about-utsa {
		background-image: url("../../../visitors/images/bb-about-utsa.jpg");

		@include media-breakpoint-up(lg) {
			background-image: url("../../../visitors/images/bb-about-utsa-2x.jpg");
		}
	}

	&.bb-our-city {
		background-position: bottom center;
		background-size: contain;
		background-image: url("../images/backgrounds/bb-our-city.svg");
		padding: 6rem 0 10rem;
		background-attachment: fixed;

		@include media-breakpoint-up(md) {
			padding: 6rem 0 20rem;
		}
	}

	&.bb-mariachis {
		background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/backgrounds/bb-mariachis-2x.jpg");
		position: relative;
		z-index: -1;

		@include media-breakpoint-down(md) {
			background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/backgrounds/bb-mariachis.jpg");
		}

		.col-sm-12 {
			position: relative;
			z-index: 1;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			height: 100%;
			width: 100%;
			background-color: rgba($utsa-blue, 0.75);
			z-index: 0;
		}
	}
}

.bg-pattern {
	background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 7px, rgba(255, 255, 255, 0.1) 0);

	&.bgp-orange {
		background-color: $orange-a11y;

		p {
			color: $white;
		}
	}
}

.bg-gray .special-heading:after {
	background-color: $utsa-orange;
}
