.visually-hidden {
    @extend .visually-hidden;
    @include ie-fix() {
        left: -100000px;
        position: absolute;
    }
    @include ff-fix() {
        left: -100000px;
        position: absolute;
    }
}

.visually-hidden-focusable {
    @extend .visually-hidden;
    @include ie-fix() {
        left: -100000px;
        position: absolute;
    }
    @include ff-fix() {
        left: -100000px;
        position: absolute;
    }
}
