// Color Utilities
.bg-white,
.bg-light {
	background-color: $white;

	p {
		color: $black;
	}

	.display-1,
	.display-2,
	.display-3,
	.display-4,
	.display-5,
	.display-6,
	.special-heading {
		color: $utsa-blue;
	}

	@include headings {
		color: $utsa-blue;
	}
}

.bg-gray {
	background-color: $gray-00;

	p {
		color: $black;
	}
	.display-1,
	.display-2,
	.display-3,
	.display-4,
	.display-5,
	.display-6,
	.special-heading {
		color: $utsa-blue;
	}

	@include headings {
		color: $utsa-blue;
	}

	.special-heading {
		.year,
		.kicker {
			color: $blue-02;
		}

		&:after {
			background-color: $blue-02;
		}
	}

	.table {
		color: $blue-02;
		border-color: $blue-02;

		& > :not(:first-child) {
			border-top: 2px solid;
			border-color: $blue-02;
		}
	}

	// .stat
	.stat {
		background-color: rgba($white, 0.5);
	}
}

.bg-primary,
.bg-utsa-blue,
.bg-dark,
.bg-blue {
	.color-chip {
		@include headings {
			color: $utsa-blue;
		}
		p {
			color: $utsa-blue;
		}
	}

	ul {
		color: $white;
	}

	a:not(.btn) {
		color: $white;

		&.link-arrow {
			color: $white;

			&:hover,
			&:focus {
				color: $white;
				background-color: $orange-a11y;

				&:after {
					color: $white;
				}
			}
		}
	}

	figure {
		figcaption {
			color: $white;
		}
	}

	.table {
		color: $white;
		border-color: $blue-02;
		& > :not(:first-child) {
			border-top: 2px solid;
			border-color: $blue-02;
		}
	}

	.display-1,
	.display-2,
	.display-3,
	.display-4,
	.display-5,
	.display-6,
	.link-arrow {
		color: $white;
	}

	@include headings {
		color: $white;
		&.special-heading {
			.year,
			.kicker {
				color: $utsa-orange;
			}

			&:after {
				background-color: $utsa-orange;
			}
		}
	}

	.card-body {
		blockquote {
			color: $white;
			p,
			.bq-name strong {
				color: $white;
			}
		}
	}

	&.card {
		.card-body {
			.special-heading {
				color: $white;
			}

			p {
				color: $white;
			}
		}
	}

	// .stat
	.stat {
		background-color: rgba($black, 0.25);
		border-color: $blue-02;

		.stat-title,
		.stat-subtitle {
			color: $white;
		}

		.source {
			color: $utsa-orange;

			& > a {
				color: $utsa-orange;
			}
		}
	}
}

.bg-secondary-a11y,
.bg-secondary,
.bg-a11y-orange,
.bg-orange {
	color: $white;
	background-color: $orange-a11y;

	p,
	.display-1,
	.display-2,
	.display-3,
	.display-4,
	.display-5,
	.display-6,
	.special-heading {
		color: $white;
	}

	@include headings {
		color: $white;
	}

	.special-heading {
		.year,
		.kicker {
			color: $white;
		}

		&:after {
			background-color: $white;
		}
	}

	.table {
		color: $black;
		border-color: $black;

		& > :not(:first-child) {
			border-top: 2px solid;
			border-color: $black;
		}
	}

	.icon-link {
		@include headings {
			color: $white;
		}
	}
}

.bg-blue-02 {
	background-color: $blue-02;
	color: $white;

	p,
	.display-1,
	.display-2,
	.display-3,
	.display-4,
	.display-5,
	.display-6,
	.special-heading {
		color: $white;
	}

	@include headings {
		color: $white;
	}

	.special-heading {
		.year,
		.kicker {
			color: $white;
		}

		&:after {
			background-color: $white;
		}
	}

	.table {
		color: $gray-01;
		border-color: $gray-01;

		& > :not(:first-child) {
			border-top: 2px solid;
			border-color: $gray-01;
		}
	}
}

// Color Chips
.color-chip {
	box-shadow: $shadow;
	margin: 1rem 0;
	background-color: $white;

	.cc-chip {
		padding: 4.5rem 1rem;
		border-bottom: 1px solid $gray-01;
	}

	.ccc-utsa-blue {
		background-color: $utsa-blue;
	}

	.ccc-utsa-orange {
		background-color: $utsa-orange;
	}

	.ccc-orange-a11y {
		background-color: $orange-a11y;
	}

	.ccc-blue-02 {
		background-color: $blue-02;
	}

	.ccc-white {
		background-color: $white;
	}

	.ccc-gray-00 {
		background-color: $gray-00;
	}

	.ccc-gray-01 {
		background-color: $gray-01;
	}

	.ccc-gray-03 {
		background-color: $gray-03;
	}

	.ccc-gray-05 {
		background-color: $gray-05;
	}

	.ccc-gray-f6 {
		background-color: $gray-f6;
	}

	.ccc-black {
		background-color: $black;
	}

	footer {
		padding: 1rem 1rem;
		display: flex;
		align-items: center;
		column-gap: 4rem;
		justify-content: center;

		* {
			margin: 0;
		}
	}
}
