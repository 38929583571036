.content-row {
	padding: 6rem 0 6rem;

	@include media-breakpoint-down(md) {
		padding: 4rem 0;
	}

	// Remove to work with Jenny's changes
	.row-neg {
		margin-top: -10rem;
	}
}

.content-row,
.row {
	z-index: 1;
}
