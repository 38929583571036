.hi-academics {
	background-image: url("/_files/academics/images/hero-academics-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("/_files/academics/images/hero-academics.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("/_files/academics/images/hero-academics-2x.jpg");
	}
}
