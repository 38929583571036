.subnav {
	background-color: $blue-02;
	position: relative;
	ul {
		list-style: none;
		text-align: center;
		padding: 1rem;
		white-space: nowrap;
		overflow-y: hidden;
		overflow-x: auto;
		scrollbar-width: thin;

		@media (hover: none) and (pointer: coarse) {
			scrollbar-width: none;
		}

		&:before {
			background: linear-gradient(to left, $blue-02 20%, rgba(255, 255, 255, 0) 80%);
			height: 50px;
			width: 50px;
			content: "";
			pointer-events: none;
			display: block;
			z-index: 11;
			position: absolute;
			right: 0;
			top: 0;
		}

		li {
			display: inline-block;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-right: 1px solid rgba($white, 0.35);
			margin-bottom: 0;

			&:last-of-type {
				border-right: none;
			}

			a {
				color: $white;
				border: none;
				font-weight: $bold;

				&.active {
					border-bottom: 2px solid $white;
				}

				&:focus,
				&:hover {
					background-color: transparent;
					border-bottom: 1px solid $white;
				}
			}
		}
	}
}
