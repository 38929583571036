.icon {
	&-link {
		display: block;
		width: 100%;
		height: 100%;
		border: 1px solid transparent;
		padding: 2rem;
		color: $utsa-orange;
		text-decoration: none;
		text-align: center;
		margin: 1rem 0;

		img {
			margin-bottom: 1rem;
		}

		&:hover,
		&:focus {
			outline: transparent;
			background-color: transparent;
			img {
				transform: scale(1.1);
			}
		}

		&:focus {
			border: 1px solid $white;
		}

		@include headings {
			color: $utsa-orange;
			margin: 0;
		}
		// .icon-link-unified
		&-unified {
			text-align: center;
			margin: 1rem 0;

			a {
				border: none;
				display: inline-block;
				padding: 0.5rem;

				&:focus,
				&:hover {
					border: 0;
					transform: translateY(-0.5rem);
				}
			}
			img {
				margin: 0 0 1.25rem 0;
			}

			@include headings {
				font-size: 1.25rem;
			}
		}
	}
}
