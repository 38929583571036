::marker {
	unicode-bidi: isolate;
	font-variant-numeric: tabular-nums;
	text-transform: none;
	color: $orange-a11y;
}

ul {
	padding: 1.5rem 2rem;

	li {
		line-height: 1.5;
		margin-bottom: 0.5rem;
	}

	&.list-links {
		columns: auto rem-calc(300);
		gap: 15rem;

		li {
			font-weight: $bold;
			break-inside: avoid-column;
			page-break-inside: avoid;

			ul {
				margin: 0;
				padding: 0.5rem 0 0 1.25rem;
				li {
					list-style-type: disc;
				}
			}

			a {
				&.file-type {
					&.ft-pdf {
						&:after {
							color: $orange-a11y;
							font-family: $fa;
							font-size: 0.9rem;
							content: "\f1c1";
							margin-left: 0.5rem;
						}
					}

					&.ft-pptx {
						&:after {
							color: $orange-a11y;
							font-family: $fa;
							font-size: 0.9rem;
							content: "\f1c4";
							margin-left: 0.5rem;
						}
					}
				}
			}
		}
	}
}

details {
	margin: 0.5rem 0;
}
summary {
	font-weight: $bold;
}
