/* === DLS Variables === */
// + Colors
$utsa-blue: #0c2340;
$utsa-orange: #f15a22;
$orange-a11y: #d3430d;
$white: #fff;
$blue-highlight: #025efc;
$blue-02: #495a70;
$gray-00: #f1f2f4;
$gray-01: #dbdee3;
$gray-03: #949494;
$gray-05: #232323;
$gray-f6: #f6f6f6;
$black: #000;

// + Fonts
$base-font: 16px;
$footer-font-size: 0.875rem;
$subfooter-font-size: 0.75rem;
$body-font: open-sans, sans-serif;

// ++ Weights
$normal: 400;
$med: 600;
$bold: 700;
$black-weight: 900;

// ++ Families
$heading-font: ff-meta-web-pro, serif;
$numbers: $heading-font;
$fa: "Font Awesome 6 Pro", "Font Awesome 6 Pro Brands";
$fa-sharp: "Font Awesome 6 Sharp", "Font Awesome 6 Pro";
$line-height: 1.15;

// + Shadows
$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.2) 0px 0px 8px;
$nav-shadow: 1px 1px 6px 0 rgba($utsa-blue, 0.2);
$sm-shadow: rgba($black, 0.05) 0px 1px 2px 0px;

// + Transitions
$transition: all cubic-bezier(0.33, 0.73, 0.72, 0.26) 10ms;

// + Border
$border-width: rem-calc(5);

/* === BS5 Overrides === */
// ++ Buttons
// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0.1875rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: $btn-padding-x;
$btn-font-size-sm: $base-font;
$btn-padding-y-lg: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;
$btn-font-size-lg: $base-font;

// ++ Tooltip
$tooltip-bg: $black;
$tooltip-opacity: 1;
$tooltip-max-width: rem-calc(250);
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;

// ++ Input
$input-border-width: 1px;
$input-focus-width: 1px;
$input-focus-box-shadow: inset 0 0 0 0.15rem rgba($utsa-blue, 0.15);

// ++ Color
$primary: $utsa-blue;
$secondary: $orange-a11y;
$light: $white;
$dark: $utsa-blue;

// ++ Navbar
// local vars
$nav-link-y-padding: 1rem;
$nav-link-x-padding: 1rem;
$nav-link-margin-right: 0.5rem;
// Add BS5 vars below

// ++ Dropdowns
$dropdown-padding-x: 0.75rem;
$dropdown-padding-y: 0.75rem;
$dropdown-spacer: 0; // How far away the dropdown is from the parent link
$dropdown-min-width: rem-calc(205);
$dropdown-font-size: $base-font;
$dropdown-color: $blue-02;
$dropdown-bg: $gray-00;
$dropdown-border-color: transparent;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-box-shadow: $sm-shadow;
$dropdown-link-color: $utsa-blue;
$dropdown-link-hover-color: $utsa-blue;
$dropdown-link-hover-bg: $gray-01;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 0.5rem;

// ++ Fonts
$font-family-sans-serif: $body-font;
$headings-font-family: $heading-font;
// ++ Display Headings
$display-font-sizes: (
	1: 5rem,
	2: 4.5rem,
	3: 4rem,
	4: 3.5rem,
	5: 3rem,
	6: 2.5rem,
);
$display-line-height: $line-height;
$display-font-weight: 700;

// ++ Grid
$grid-gutter-width: 2rem;
$grid-breakpoints: (
	xs: 0,
	sm: 600px,
	md: 991px,
	lg: 1440px,
	xl: 1920px,
	xxl: 2560px,
);

$container-max-widths: (
	sm: 95%,
	md: 95%,
	lg: 1320px,
	xl: 1320px,
	xxl: 1400px,
);

// ++ Borders
$card-border-radius: $btn-border-radius;
$border-color: $utsa-orange;
$border-width: 1px;
$border-widths: (
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
);

// ++ Spacing
$spacer: 0.5rem;

$spacers: (
	0: 0,
	1: $spacer * 2,
	// 1
	2: $spacer * 4,
	// 2
	3: $spacer * 6,
	// 3
	4: $spacer * 8,
	// 4
	5: $spacer * 12,
	//6
);

// ++ Weights
$font-weight-lighter: $normal;
$font-weight-light: $normal;
$font-weight-normal: $normal;
$font-weight-semibold: $med;
$font-weight-bold: $bold;
$font-weight-bolder: $black-weight;

/* === Font Awesome Overrides === */
$fa-font-path: "../fonts/font-awesome";
