/* This is Jenny Gonzalez's standard grid styling they use when they want to display columns without using Bootstrap */
// .grid-container {
// 	margin: 0;
// 	padding: 0;
// 	column-gap: 2rem;
// 	row-gap: 1.25rem;
// 	width: 100%;
// 	display: grid;
// }
// .grid-container li {
// 	list-style: none;
// 	margin: 0;
// 	padding: 0;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// }
// .grid-container.column-2 {
// 	grid-template-columns: repeat(4, 1fr);
// }
// .grid-container.column-2 li {
// 	grid-column: span 2;
// 	width: 100%;
// }
// .grid-container.column-3 {
// 	grid-template-columns: repeat(6, 1fr);
// }
// .grid-container.column-3 li {
// 	grid-column: span 2;
// 	width: 100%;
// }
// .grid-container.column-3 li.span-2 {
// 	grid-column: span 4;
// 	width: 100%;
// }
// .grid-container.column-4 {
// 	grid-template-columns: repeat(8, 1fr);
// }
// .grid-container.column-4 li {
// 	grid-column: span 2;
// 	width: 100%;
// }
// .grid-container.column-5 {
// 	grid-template-columns: repeat(10, 1fr);
// }
// .grid-container.column-5 li {
// 	grid-column: span 2;
// 	width: 100%;
// }
