/* Typescale */
/* Headings, paragraphs sizes*/
body,
html {
	font-size: $base-font;
}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.5rem;
}

h3 {
	font-size: 2.25rem;
}

h4 {
	font-size: 2rem;
}

h5 {
	font-size: 1.75rem;
}

h6 {
	font-size: 1.5rem;
}

blockquote {
	font-size: 1.125rem;

	&.bq-lg {
		font-size: 2.25rem;
	}

	.bq-name {
		font-size: 1rem;
	}
}

.lead {
	font-size: 1.25rem;
}

/* Bootstrap Overrides*/
