// local vars
$bottom-margin: 20%;

.social-media-feed {
	background-color: $orange-a11y;
	padding: 8rem 0;
	color: $white;
	position: relative;
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		margin-bottom: $bottom-margin;
	}

	a,
	p {
		color: $white;
	}

	@include headings {
		color: $white;
	}

	.feed {
		position: relative;
		transform: translate(0, 0);
		left: unset;
		top: unset;
		margin-top: 1rem;

		a {
			border: none;
			color: $utsa-blue;
			font-weight: $bold;
			margin: 1rem 0 0;
			display: block;

			@include media-breakpoint-down(lg) {
				color: $white;
			}

			&:hover,
			&:focus {
				color: $blue-02;
				text-decoration: underline;
				outline: none;
				background: none;
				border: none;

				@include media-breakpoint-down(lg) {
					color: $gray-01;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			transform: translate(-50%, 10%);
			left: 50%;
			top: 50%;
		}

		ul {
			margin: 0;
			padding: 0;
			li {
				display: inline;
				padding: 1rem;
			}
		}

		a {
			&:focus {
				img {
					transform: rotate(1deg);
					border-bottom: 4px solid $utsa-orange;
				}
			}

			img {
				transition: 100ms ease-in;

				&:hover {
					transform: rotate(1deg);
					border-bottom: 4px solid $utsa-orange;
				}
			}
		}
	}
}
