.hi-campus-life {
	background-image: url("/_files/academics/images/hero-campus-life-mobile.jpg");

	@include media-breakpoint-up(md) {
		background-image: url("/_files/academics/images/hero-campus-life.jpg");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("/_files/academics/images/hero-campus-life-2x.jpg");
	}
}
