// Hero Images
.hi-about-campuses-downtown {
	background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-mobile-2x.jpg");
	@include media-breakpoint-up(md) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown.jpg");
	}

	@include media-breakpoint-up(lg) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-2x.jpg");
	}
}

.hi-about-wellbeing {
	background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-mobile-2x.jpg");
	@include media-breakpoint-up(md) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown.jpg");
	}

	@include media-breakpoint-up(lg) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-2x.jpg");
	}
}

.hi-about-dining {
	background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-mobile-2x.jpg");
	@include media-breakpoint-up(md) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown.jpg");
	}

	@include media-breakpoint-up(lg) {
		background-image: url("https://www.utsa.edu/_files/about/images/hero/hero-downtown-2x.jpg");
	}
}

// Landing Page Cards
.cbi-wellbeing {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-wellbeing.jpg");

		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-wellbeing-2x.jpg");
		}
	}
}

.cbi-parking {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-parking.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-parking-2x.jpg");
		}
	}
}

.cbi-dining {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-dining.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-dining-2x.jpg");
		}
	}
}

.cbi-academic-support {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-academic-support.jpg");

		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-academic-support-2x.jpg");
		}
	}
}

.cbi-safety {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-safety.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-safety-2x.jpg");
		}
	}
}

.cbi-utsacard {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-utsacard.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-utsacard-2x.jpg");
		}
	}
}

.cbi-career-services {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-career-services.jpg");
		@include media-breakpoint-up(lg) {
		}
	}
}

.cbi-rowdy-store {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-rowdy-store.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-rowdy-store-2x.jpg");
		}
	}
}

.cbi-one-stop {
	&:after {
		background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-onestop.jpg");
		@include media-breakpoint-up(lg) {
			background-image: url("https://www.utsa.edu/_files/about/images/cards/downtown/card-onestop-2x.jpg");
		}
	}
}
