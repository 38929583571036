ul {
	&.icon-list {
		display: block;
		margin: 0;
		padding: 0;
		// Place modifier higher so :last-of-type works

		a {
			text-decoration: none;
			display: inline-block;
			height: 2.5rem;
			width: 2.5rem;
			border: 1px solid $white;
			text-align: center;
			padding: 0.85rem 0;
			border-radius: 100%;
			font-size: 1.25rem;
			padding: 0.25rem;
			outline: none;

			@include media-breakpoint-down(md) {
				height: 2.125rem;
				width: 2.125rem;
				font-size: 1rem;
			}

			&.il-facebook {
				@include fa-icon-brands($fa-var-facebook-f);
				line-height: 1.5;
			}

			&.il-twitter {
				@include fa-icon-brands($fa-var-x-twitter);
				line-height: 1.5;
			}

			&.il-youtube {
				@include fa-icon-brands($fa-var-youtube);
				line-height: 1.5;
			}

			&.il-linkedin {
				@include fa-icon-brands($fa-var-linkedin-in);
				line-height: 1.5;
			}

			&.il-instagram {
				@include fa-icon-brands($fa-var-instagram);
				line-height: 1.5;
			}
		}

		&.il-solid {
			li {
				margin: 0 0.5rem 0 0;
				display: inline;

				a {
					color: $orange-a11y;
					background-color: $white;
					display: inline-block;
					height: rem-calc(52);
					width: rem-calc(52);
					line-height: 2.15;

					&:hover,
					&:focus {
						background-color: $utsa-blue;
						border-color: transparent;
					}
				}
			}
		}

		&.il-lg {
			a {
				border-width: rem-calc(3);
				font-size: 2rem;
				height: rem-calc(60);
				width: rem-calc(60);
			}
		}

		&.il-outline-orange {
			margin: 0 0.5rem 0 0;
			display: flex;
			gap: 1rem;
			justify-content: center;
			align-content: space-between;
			flex-wrap: wrap;
			a {
				color: $orange-a11y;
				border-color: $orange-a11y;

				&:focus,
				&:hover {
					color: $utsa-blue;
					border-color: $utsa-blue;
				}
			}
		}

		li {
			margin: 0 1rem 0 0;
			display: inline-block;

			&:last-of-type {
				margin: 0;
			}
		}
	}
}
