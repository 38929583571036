@mixin headings {
	h1, h2, h3, h4, h5, h6 {
	  @content;
	}
  }

  /*
	http://www.sitepoint.com/sass-mixin-placeholder/

	Mixins allow you to define styles that can be re-used throughout the stylesheet
	without needing to resort to non-semantic classes like .float-left. Mixins can
	also contain full CSS rules, and anything else allowed elsewhere in a Sass
	document. They can even take arguments which allows you to produce a wide
	variety of styles with very few mixins.
 */

@mixin hover-active-focus($property, $value) {
    &:hover,
    &:active,
    &:focus {
        #{$property}: $value;
    }
} // to use: @include hover-active-focus(property, value);

/* Mobile */
@mixin mobile-max {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
} // to use: @include mobile-max-max (property: value;)

@mixin mobile-min {
    @media (min-width: $breakpoint-mobile) {
        @content;
    }
} // to use: @include mobile-max-max {property: value;}

/* Desktop */
@mixin desktop-min {
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
} // to use: @include desktop-min {property: value;}

// targets ie
@mixin ie-fix() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

//targets ff
@mixin ff-fix() {
    @-moz-document url-prefix() {
        @content;
    }
}
