.global-footer {
	background-color: $utsa-blue;
	color: $white;
	padding: 4rem 0 2.5rem;
	font-size: $footer-font-size;
	border-top: 4px solid $utsa-orange;

	@include media-breakpoint-down(sm) {
		padding: 3rem 0 2.5rem;
	}

	.footer-logo {
		display: inline-block;
		& > img {
			max-height: 80px;

			@include media-breakpoint-down(sm) {
				max-height: 65px;
				max-width: 215px;
			}
		}

		&:focus,
		&:hover {
			background-color: transparent;
		}
	}

	.gf-contact {
		margin: 0;

		address {
			margin: 1.5rem 0 0;
		}
	}

	.social-media-footer-container {
		margin: 1.5rem 0 0;

		@include media-breakpoint-down(md) {
			margin: 1.5rem 0;
		}

		ul.icon-list {
			display: flex;
			column-gap: 0.75rem;

			li {
				margin: 0;
				a {
					background-color: transparent;

					&:hover,
					&:focus {
						border: 2px solid $white;
					}
				}
			}
		}
	}

	.global-footer-links {
		column-count: 1;

		@include media-breakpoint-down(lg) {
			margin-top: 0;
		}

		@include media-breakpoint-up(md) {
			column-count: 3;
		}

		@include media-breakpoint-down(md) {
			column-count: 2;
		}

		@include media-breakpoint-down(sm) {
			column-count: 1;
		}
	}

	p {
		color: $white;
	}

	a {
		color: $white;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		font-weight: $normal;

		&:hover,
		&:focus {
			background-color: $blue-02;
			outline: none;
			border-bottom: 1px solid $white;
		}
	}

	hr {
		height: auto;
		border-top: 2px solid rgba($white, 0.75);
		margin: 2.5rem 0;
	}

	ul {
		margin: 0.625rem 0 0;
		padding: 0;
		li {
			list-style: none;
			margin: 0 0 0.625rem 0;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.copyright-subfooter {
		text-align: center;
		margin: 0 auto;
		font-size: $subfooter-font-size;
		max-width: 100%;
		align-items: center;
		display: flex;
		justify-content: center;

		@include media-breakpoint-down(md) {
			display: block;
		}

		ul {
			li {
				display: inline-block;
				margin: 0;

				&:last-of-type {
					margin: 0;
				}
			}
		}

		.utsa-copyright {
			text-align: end;
			max-width: 50%;
			float: left;
			padding-right: 1rem;

			@include media-breakpoint-down(md) {
				max-width: 100%;
				float: none;
				text-align: center;
				padding: 0;
			}
		}

		.subfooter-links {
			text-align: left;
			margin: 0;
			border-left: 1px solid rgba($white, 0.5);
			padding-left: 1rem;
			max-width: 50%;
			float: left;

			@include media-breakpoint-down(md) {
				max-width: 100%;
				float: none;
				text-align: center;
				border-left: none;
				padding: 0;
			}

			ul {
				margin: 0; //trbl

				li {
					margin: 0 0.75rem 0 0;
					line-height: 1;

					&:last-child {
						margin: 0.25rem 0rem 0 0;
					}

					@include media-breakpoint-down(md) {
						margin: 0.25rem 0.75rem 0 0;
					}

					a {
						text-decoration: none;
						border-bottom: 1px solid $white;
					}
				}
			}
		}

		p,
		span {
			font-size: $subfooter-font-size;
			margin: 0;
		}
	}

	&.gf-secondary {
		.social-media-footer-container {
			margin: 0 0 1.5rem;

			@include media-breakpoint-down(lg) {
				margin: 1.5rem 0 1.5rem;
			}

			ul.icon-list {
				justify-content: end;

				@include media-breakpoint-down(lg) {
					justify-content: start;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.footer-logo > img {
				max-height: 25px;
			}
		}

		@include media-breakpoint-down(sm) {
			hr {
				margin: 1.875rem 0 2.5rem;
			}
		}

		.gf-contact {
			margin: 0;

			p {
				margin: 0;
			}

			address {
				margin: 1.5rem 0 0 0;

				@include media-breakpoint-down(sm) {
					margin-top: 1rem;
				}
			}

			&:nth-child(2) {
				text-align: left;

				@include media-breakpoint-down(lg) {
					text-align: end;
					margin-top: 2rem;
				}

				@include media-breakpoint-down(md) {
					text-align: left;
				}
			}
		}

		.global-footer-links {
			column-count: 1;
			text-align: right;

			@include media-breakpoint-down(lg) {
				text-align: left;
				margin-top: 0;
			}

			@include media-breakpoint-down(md) {
				column-count: 2;
				margin-top: 0.5rem;
			}

			@include media-breakpoint-down(sm) {
				column-count: 1;
			}

			ul {
				li {
					display: inline-block;
					margin: 0 0 0 0.75rem;
					border-left: 1px solid rgba(255, 255, 255, 0.35);
					padding-left: 0.75rem;

					&:last-of-type {
						margin: 0 0 0 0.5rem; //trbl
					}

					&:first-of-type {
						margin: 0;
						border-left: 0px;
						padding-left: 0;
					}

					@include media-breakpoint-down(md) {
						margin: 0 0 0.625rem;
						display: block;
						border-left: 0;
						padding-left: 0;

						&:first-of-type {
							margin: 0 0 0.625rem;
							border-left: 0px;
							padding-left: 0;
						}

						&:last-of-type {
							margin: 0 0 0.625rem; //trbl
						}
					}
				}
			}
		}
	}

	&.gf-affiliate {
		padding: 1.25rem 0;

		.copyright-subfooter {
			text-align: right;
			justify-content: end;

			.subfooter-links {
				text-align: left;
				margin: 0;
				border-left: 0;
				padding-left: 0;
				max-width: 100%;
				float: none;

				ul {
					li {
						margin: 0 0 0 0.75rem;
						border-left: 1px solid rgba(255, 255, 255, 0.35);
						padding-left: 0.75rem;

						&:first-child {
							margin-left: 0;
							border-left: 0px;
							padding-left: 0;
						}
					}
				}
			}
		}

		.utsa-copyright {
			p {
				margin: 0.5rem 0 0;
				font-size: 0.75rem;
				line-height: 1.2;
			}
		}

		.footer-logo {
			display: inline-block;

			& > img {
				max-height: 25px;
				width: auto;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.global-footer.gf-affiliate .footer-logo > img,
	.global-footer.gf-secondary .footer-logo > img {
		max-height: 20px;
	}
}

@include media-breakpoint-down(md) {
	.global-footer.gf-affiliate .copyright-subfooter .subfooter-links {
		margin: 1rem 0 0;
	}
	.global-footer.gf-affiliate .footer-logo > img,
	.global-footer.gf-secondary .footer-logo > img {
		max-height: 65px;
	}

	.global-footer.gf-affiliate .copyright-subfooter .subfooter-links ul li {
		margin: 0.25rem 0.75rem 0 0;
		border-left: 0px;
		padding-left: 0;
	}

	.global-footer.gf-affiliate .copyright-subfooter .subfooter-links ul li:last-child {
		margin: 0.25rem 0rem 0 0;
	}

	.global-footer.gf-affiliate .copyright-subfooter .subfooter-links {
		margin: 0.5rem 0 0;
	}
}
