.breadcrumbs {
	padding: 2rem 0 0;
	.crumbs {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: rem-calc(12);
		font-weight: $bold;
		color: $utsa-blue;
		line-height: 165%;

		li {
			display: inline;

			a {
				text-decoration: none;
				border: none;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			&:after {
				content: "\f105";
				font-family: $fa;
				font-weight: $normal;
				margin: 0 rem-calc(5);
				font-size: rem-calc(20);
				display: inline-block;
				color: $utsa-orange;
				vertical-align: middle;
			}
		}
	}
}
