input {
	&.global-searchfield {
		border-radius: $btn-border-radius;
		border: 1px solid $gray-00;
	}
}

.input-group {
	.btn {
		margin-top: 0;
		margin-bottom: 0;
	}
}
