hr {
	height: auto;
	border-top: 2px solid rgba($utsa-blue, 0.75);
	margin: rem-calc(64) 0;

	&.hr-dashed {
		border-color: $gray-03;
		border-style: dashed;
		border-width: 0.063rem;
		margin-right: 5%;
		margin-left: 5%;
	}
}
