.skip-to-content {
	@include visually-hidden-focusable;
}

.btn {
	margin: 1rem 1rem 0 0;
	font-weight: $bold;

	&:last-of-type {
		margin-right: 0;
	}

	&.btn-primary {
		&:hover {
			background-color: $blue-02;
			border-color: $blue-02;
		}
	}

	&-arrow {
		&:after {
			content: "\f061";
			font-family: $fa;
			margin-left: 1rem;
		}
	}

	&-search {
		background-color: $orange-a11y;
		color: $white;
		width: 100%;

		&:before {
			content: "\f002";
			font-family: $fa;
			display: block;
		}
	}

	&-gray {
		background-color: $gray-01;
		color: $utsa-blue;
	}
}

.btn-block-group {
	.btn {
		display: block;
		width: 100%;
		max-width: rem-calc(520);
	}
	.btn-arrow {
		text-align: left;
		&:after {
			float: right;
			margin-left: 1rem;
		}
	}
}
