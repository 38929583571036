a {
	.card p {
		font-weight: $normal;
	}
}

.card {
	border-color: $gray-01;
	height: 100%;
	box-shadow: $shadow;
	border-radius: $btn-border-radius;
	border-width: 0;

	img {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.card-body ul {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&-bg-img {
		height: auto;
		min-height: 20rem;
		background-color: $utsa-blue;
		display: flex;
		margin-bottom: 0.5rem;
		position: relative;
		border-radius: 0;
		z-index: 1;
		overflow: hidden;

		&:hover,
		&:focus,
		&:focus-within {
			&:after {
				transform: scale(1.15);
			}

			&:before {
				background-color: rgba($utsa-blue, 0.92);
			}

			.card-body,
			.card-title {
				color: $white;
				text-decoration: underline;
			}
		}

		&:before {
			background-color: rgba($utsa-blue, 0.65);
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}

		&:after {
			transition: transform ease-in 300ms;
			background-size: cover;
			background-position: center;
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
		}

		.card-body {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			z-index: 1;
			position: relative;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

			@include headings {
				&.card-title {
					color: $white;
					padding: 0;
				}
			}
		}
	}

	&-horizontal {
		height: auto;
		padding: 0;

		&.bg-chevron {
			&:after {
				z-index: 0;
			}
		}
	}

	&-border {
		border: rem-calc(4) $utsa-orange solid;

		&.cb-top {
			border-left: none;
			border-right: none;
			border-bottom: none;

			.card-img {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}

		&.cb-bottom {
			border-left: none;
			border-right: none;
			border-top: none;
		}
	}

	&-img {
		height: auto;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.card-footer {
		border: none;
		background-color: transparent;
		padding: 0 1.5rem 3rem;

		&.cf-bottom {
			background-color: rgba($black, 0.03);
			border-top: 1px solid rgba($black, 0.125);
			padding: 1.25rem 2rem;

			p {
				margin-bottom: 0;
				display: inline-block;
			}
		}
	}

	.card-body {
		padding: 2rem;
		line-height: 1.5;
		white-space: normal;

		p {
			color: $black;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		@include headings {
			color: $utsa-blue;

			&:has(+ p) {
				margin-bottom: 1rem;
			}
		}
	}

	.card-text {
		margin-bottom: 1rem;
	}

	@include headings {
		&.card-title {
			font-size: 1.75rem;
			margin: 0;
		}
	}

	&-gray {
		background-color: $gray-00;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.1);
		box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.2) 0px 0px 8px;
	}

	&-article {
		padding: 0;
		margin: 0;
		height: auto;
		overflow: hidden;

		.card-body {
			@include headings {
				color: $orange-a11y;
			}
		}

		.article-img {
			background-color: $utsa-orange;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top center;
			min-height: rem-calc(240);
		}
	}
}

// Linked Card
a {
	&.card-link {
		&:hover,
		&:focus {
			.card {
				border-color: $utsa-blue;
				transform: translateY(-0.5rem);

				&.card-bg-img {
					transform: translateY(0);

					.card-body {
						@include headings {
							&.card-title {
								color: $white;
								text-decoration: underline;
							}
						}
					}
				}

				.card-body {
					@include headings {
						color: $orange-a11y;
					}
				}
			}
		}

		img {
			display: block;
		}

		.card {
			transition: $transition;
		}
	}
}

// Enrollment Section
.enrollment .card .card-body {
	padding: 2.5rem 2rem 2rem;
}

.enrollment .card .card-footer {
	padding: 0 2rem 2.5rem;

	p {
		margin-bottom: 0;

		.btn {
			margin: 0;
		}
	}
}

// News Cards
.news {
	.card {
		.card-body {
			padding: 2rem;
		}
	}
}
