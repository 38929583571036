// Scrollbar
::-webkit-scrollbar {
	width: 1rem;
}

::-webkit-scrollbar-track {
	background-color: $gray-00;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: $orange-a11y;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
