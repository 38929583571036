.cta {
	// Featured - .cta-featured
	&-featured {
		border-top: 4px solid $utsa-orange;
		background-color: $utsa-blue;
		color: $white;
		padding: 3rem 0;

		p {
			color: $white;
		}

		@include media-breakpoint-down(md) {
			text-align: center;
		}

		.row {
			align-items: center;
		}

		img {
			max-height: 120px;
			width: auto;
			height: auto;
		}

		.btn {
			margin: 0;
		}

		@include headings {
			color: $white;
			font-size: 1.5rem;
		}
	}

	// Centered - .cta-centered
	&-centered {
		background-color: $utsa-blue;
		color: $white;
		padding: 3rem 4.75rem;
		text-align: center;

		@include media-breakpoint-up(lg) {
			text-align: unset;
		}

		p {
			color: $white;
			font-size: 1.5rem;

			margin: 0 0 1rem 0;

			@include media-breakpoint-down(lg) {
				margin: 1.5rem 0;
			}
		}

		.btn {
			margin-top: 0;
		}

		img {
			max-height: rem-calc(120);
			height: rem-calc(120);

			@include media-breakpoint-up(lg) {
				padding-right: 4rem;
			}
		}

		.row {
			align-items: center;
		}
	}
	// Titled - .cta-titled
	&-titled {
		align-items: center;
		background-color: $orange-a11y;
		color: $white;
		padding: 4rem 2rem;

		@include headings {
			color: $white;
		}

		p {
			color: $white;
		}

		@include media-breakpoint-up(lg) {
			.btn,
			p {
				margin: 0;
			}
		}
	}
}
