.hero {
	width: 100%;
	background-color: $utsa-blue;

	&.hero-video {
		position: relative;
		&:before {
			content: "";
			background-color: rgba($utsa-blue, 0.5);
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}

	.jquery-background-video-wrapper {
		min-height: rem-calc(640);
		width: 100%;
		height: 100%;
		background-color: $utsa-blue;

		.jquery-background-video-pauseplay {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 11;
			background-color: transparent;
			border: 0;

			&:hover,
			&:focus {
				outline: 1px solid $white;
			}

			&:after {
				font-size: 2rem;
				color: $white;
				font-family: $fa;
				font-weight: 900;
				text-shadow: $text-shadow;
			}

			&.pause:after {
				content: "\f144";
			}

			&.play:after {
				content: "\f28b";
			}

			span {
				display: none;
				visibility: hidden;
				height: 0;
				width: 0;
			}
		}

		.hero-text-container {
			color: $white;
			text-shadow: $text-shadow;
			position: absolute;
			width: 100%;
			bottom: 0;
			padding: 4rem 2rem;
			text-align: center;
			z-index: 10;

			p {
				color: $white;
				font-size: 1.75rem;
			}

			.btn {
				text-shadow: none;
			}

			@include headings {
				text-transform: uppercase;
				color: $white;
			}
		}
	}

	&-image {
		height: rem-calc(470);
		background-position: center;
		background-size: cover;
		position: relative;
		z-index: 10;

		// @include media-breakpoint-up(lg) {
		// }

		&.hi-subpage {
			height: rem-calc(420);
		}

		.hi-title {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			background-color: rgba($utsa-blue, 0.9);
			padding: 1rem 4.75rem;

			h1 {
				color: $white;
			}
		}

		&.hi-about-students {
			background-image: url("https://picsum.photos/id/11/2880/840");
		}

		&.hi-about-history-development {
			background-image: url("https://picsum.photos/id/15/2880/840");
		}

		&.hi-about-campuses {
			background-image: url("https://www.utsa.edu/preview/wss/_files/about/images/campus-locations-2022.png");
		}

		&.hi-about-hispanic-identity {
			background-image: url("https://picsum.photos/id/18/2880/840");
		}

		&.hi-about-facts {
			background-image: url("https://picsum.photos/id/48/2880/840");
		}

		&.hi-about-rankings {
			background-image: url("https://picsum.photos/id/176/2880/840");
		}

		&.hi-about-hispanic {
			background-image: url("https://picsum.photos/id/136/2880/840");
		}
	}
}
