// Mmenu Styles
@import "./node_modules/mmenu-js/dist/mmenu";

.mm-menu--theme-light,
.mm-panels,
.mm-panel {
	background-color: $white;
}

// Removes "Menu" container
.mm-menu #mm-1 .mm-navbar {
	display: none;
}

.mm-navbar {
	background-color: transparent;
	border-color: $gray-01;

	.mm-navbar__title,
	.mm-btn {
		background-color: $utsa-blue;
		color: $utsa-orange !important;

		&:hover {
			outline: none;
		}
	}

	.mm-navbar__title {
		font-size: 1.25rem;
		line-height: 1;
	}

	.mm-btn {
		&:before {
			border-color: $utsa-orange;
		}
	}
}

.mm-btn--prev {
	&:before {
		content: "\f0d9";
		font-family: $fa;
		transform: rotate(0deg);
		border: 0;
		font-size: 1.25rem;
		width: auto;
		height: auto;
		vertical-align: middle;
		padding-top: 0.9rem;
	}
}

.mm-btn--next {
	&:after {
		content: "\f0da";
		font-family: $fa;
		font-weight: $bold;
		border: none;
		color: $utsa-orange;
		transform: none;
		line-height: 0.5;
		font-size: 1.25rem;
	}
}

.mm-divider {
	border-bottom: none;
	padding: 0;
	height: 0.5rem;
	background-color: $utsa-orange;
	background-image: repeating-linear-gradient(45deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0) 2px, hsla(0, 0%, 100%, 0.04) 7px, hsla(0, 0%, 100%, 0.1) 0);
}

.mobile-logo {
	padding: 1rem 0;

	&:hover {
		outline: none;
	}
	img {
		height: 35px;
		width: auto;
	}
}

.mm-menu--selected-hover .mm-listitem__btn,
.mm-menu--selected-hover .mm-listitem__text {
	&:hover {
		background-color: $gray-00;
		outline: none;
	}

	&:active {
		opacity: 1;
		background-color: $utsa-blue;
		color: $white;

		&.mm-btn--next {
			&:after {
				color: $white;
			}
		}
	}
}

.mm-listitem {
	border-color: $utsa-orange;

	&__text {
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);
		color: $utsa-blue;
		font-weight: $bold;
		font-size: 1.125rem;
		line-height: 1;
	}

	// Changes the color of the border for the button
	&__btn:not(.mm-listitem__text) {
		border-color: $gray-01;
	}

	&.mm-listitem--selected {
		background-color: $gray-00;
		color: #c64118;
	}

	&:after {
		inset-inline-start: 0;
	}
}

.mm-navbar,
.mm-navbar__title,
.mm-logo {
	background-color: $white;
	color: $orange-a11y;
	font-weight: $bold;
	text-transform: uppercase;
}

.mm-logo {
	display: flex;
	border-bottom: 1px solid #f15a22;
}

.mm-menu a:focus-visible,
.mm-menu button:focus-visible,
.mm-menu label:focus-visible {
	outline-color: $orange-a11y;
}

.mm-navbar .mm-btn {
	&.mm-btn--close {
		background-color: transparent;
		position: relative;
		border: none;

		&:before {
			content: "\f057";
			font-family: $fa;
			font-weight: 400;
			color: $white;
			position: absolute;
		}
	}
}
.mm-menu--opened {
	.mm-navbars--bottom {
		position: absolute;
		top: 0;
		left: rem-calc(-50);
		.mm-navbar {
			background-color: transparent;
		}
	}
}

.mm-wrapper--opened .mm-wrapper__blocker {
	opacity: 0.9;
}
