// NOTE: You do not need to add in the file extension or _(underscore) in the file name, the build knows where to grab it from

// brings in functions to use before variables
@import "helpers/functions";

// Bring in variables early
@import "helpers/variables";

// Bring in BS5
@import "_vendors/bootstrap";

// Mixins
@import "helpers/mixins";

// Bring in extend
@import "helpers/extend";

/* --------------------------------------------------------
   Font Awesome
   -------------------------------------------------------- */
@import "_vendors/fa";

/* --------------------------------------------------------
   Mmenu
   -------------------------------------------------------- */
@import "_vendors/mmenu";

/* --------------------------------------------------------
   Google
   -------------------------------------------------------- */
@import "_vendors/google";

/* --------------------------------------------------------
   In Atomic Design, components are organized hierarchically based on their complexity and level of composition, starting from simple building blocks (atoms) and progressing to more complex combinations (molecules and organisms).
-------------------------------------------------------- */

/* --------------------------------------------------------
   Atoms - smallest, basic building blocks
-------------------------------------------------------- */
@import "atoms/backgrounds";
@import "atoms/buttons";
@import "atoms/colors";
@import "atoms/grid";
@import "atoms/hr";
@import "atoms/typescale";
@import "atoms/typography";
@import "atoms/icons";
@import "atoms/input";
@import "atoms/links";
@import "atoms/lists";
@import "atoms/scrollbar";
@import "atoms/selection";

/* --------------------------------------------------------
   Molecules - made of atoms
-------------------------------------------------------- */
@import "molecules/breadcrumbs";
@import "molecules/cards";
@import "molecules/content-row";
@import "molecules/ctas";
@import "molecules/icon-columns";
@import "molecules/icon-list";
@import "molecules/search-bar";
@import "molecules/stats";
@import "molecules/social-media-feed";

/* --------------------------------------------------------
   Organisms - made of molecules, ex global header
-------------------------------------------------------- */
@import "organisms/hero";
@import "organisms/global-footer";
@import "organisms/global-header";
@import "organisms/spotlight";
@import "organisms/subnav";
@import "organisms/navbar";
@import "organisms/dropdown";
@import "organisms/skip-to-content";

/* --------------------------------------------------------
   Templates - groups of organisms to form a page, ex inside page
-------------------------------------------------------- */
// @import "templates/inside-page";
/* --------------------------------------------------------
   Pages - specific instance of a Template, ex About
-------------------------------------------------------- */
@import "pages/about";
@import "pages/academics";
@import "pages/downtown";
@import "pages/campus-life";
@import "pages/visitors";
