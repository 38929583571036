/* Typography */
/* type font family, colors, weights*/
p {
	font-family: $body-font;
	margin-bottom: 1.5rem;
	color: $black;
	line-height: 1.7;
}

@include headings {
	font-weight: $bold;
	color: $utsa-blue;
	font-family: $heading-font;
	line-height: $line-height;
	font-variant-numeric: lining-nums;
}

blockquote {
	margin: 2rem 0;

	&:before {
		content: "\f10d";
		font-family: $fa;
		font-weight: 900;
		font-size: 2.25rem;
		color: $utsa-orange;
		width: 5%;
		float: left;
		text-align: right;
		margin-right: 1rem;
		line-height: 1;
	}

	p {
		display: inline-block;
		width: 90%;
	}

	.bq-name {
		text-align: right;
		display: block;
		letter-spacing: 0.075rem;
		font-style: normal;

		&:before {
			//em dash and space
			content: "\2014 \00a0";
		}

		strong {
			color: $utsa-blue;
		}

		em {
			color: $utsa-blue;
			display: block;
			font-style: normal;
			line-height: 120%;
			font-weight: $normal;
		}

		a {
			&:focus,
			&:hover {
				color: $orange-a11y;
				border-bottom: 1px solid #d3430d;
			}
		}
	}

	.bq-title {
		text-transform: uppercase;
		font-weight: $bold;
	}
}

h2 {
	&.special-heading {
		color: $utsa-blue;
		margin-top: 1rem;
		margin-bottom: 2rem;

		&:after {
			content: "";
			display: block;
			height: 0.25rem;
			margin: 1rem 0 0;
			width: 3rem;
			background-color: $utsa-orange;
		}

		.kicker {
			font-size: 1.25rem;
			line-height: 120%; /* 1.5rem */
			letter-spacing: 0.1875rem;
			color: $orange-a11y;
			display: block;
			text-transform: uppercase;
			margin-bottom: 0.25rem;
		}

		.year {
			display: block;
			font-weight: 900;
			color: #d3430d;
			font-size: 2.75rem;
		}

		address {
			font-size: rem-calc(20);
			font-family: $body-font;
			font-weight: $normal;
			text-decoration: underline;
		}
	}
}

code {
	color: $blue-02;
	background-color: $gray-f6;
	padding: 0.25rem 0.5rem;
}

.bg-primary {
	@include headings {
		color: $white;
	}

	p {
		color: $white;
	}
}

figure {
	figcaption {
		padding: 1rem 0.5rem;
		font-style: italic;
	}
}
