a.dropdown {
	&:after {
		content: "\f0d7";
		font-family: $fa;
		font-weight: $bold;
		margin-left: 0.5rem;
		color: $orange-a11y;
		width: auto;
		display: inline-block;
		font-size: 1rem;
		vertical-align: top;
	}

	&.show {
		border-bottom-color: $utsa-orange;

		&:after {
			content: "\f0d8";
		}
	}
}

.dropdown-mega {
	position: static;
}

ul.dropdown-menu,
.dropdown-list {
	li {
		margin-bottom: 0;
		border-bottom: 1px solid $utsa-orange;
		outline: none;

		a {
			font-weight: $bold;
			outline: none;

			&:focus,
			&:hover {
				border: none;
			}
		}

		&:last-of-type {
			border-bottom: 0;
		}
	}
}

.dropdown-list {
	list-style: none;
	padding: 0;
	li {
		margin: 0;
		&:last-of-type {
			a {
				border: none;
			}
		}
	}
}

// split toggle
.navbar {
	.navbar-nav {
		.nav-item {
			// Styles under .nav-item and above should be placed in navbar.scss
			&.dropdown-split {
				border-color: transparent;

				&:last-of-type {
					border-color: transparent;
				}

				// Hover for Dropdown Split .nav-item
				&:hover,
				&:focus {
					background-color: transparent;
					border-bottom: rem-calc(4) $utsa-orange solid;
					outline: none;
				}

				a.nav-link-split.nav-link,
				button.dropdown-toggle-split {
					display: inline-block;
				}

				&:after {
					content: "";
				}

				&.dropend {
					a.nav-link-split.nav-link {
						padding: $nav-link-y-padding 0.5rem $nav-link-y-padding 0.5rem; //trbl
						border: 0;
					}
				}

				a.nav-link-split.nav-link {
					margin: 0;
					padding: $nav-link-y-padding 0.25rem $nav-link-y-padding $nav-link-y-padding;
				}

				// Dropdown Split Toggle
				button.dropdown-toggle-split {
					padding: 0;
					margin: 0;
					border: none;
					outline: 1px solid transparent;
					height: rem-calc(25);
					width: rem-calc(25);

					&:focus,
					&:hover {
						// outline for dropdown button
						outline-color: rgba($gray-05, 0.5);

						&:after {
							// focus/hover color
							color: $utsa-blue;
						}
					}

					&.show {
						&:after {
							content: "\f0d8";
						}
					}

					// dropdown split arrow styles
					&:after {
						content: "\f0d7";
						font-family: $fa;
						font-weight: $bold;
						margin: 0 0.5rem;
						color: $orange-a11y;
						width: auto;
						display: inline-block;
						font-size: 1rem;
						vertical-align: top;
						border: none;
					}
				}
			}

			.dropdown-menu {
				.nav-link {
					border-color: $utsa-orange;
				}
			}
		}
	}
}

.dropdown-menu {
	li.nav-item {
		// Overrides border for nav item with a dropdown-split
		&.dropdown.dropdown-split {
			border-bottom: 1px solid $utsa-orange;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:hover,
			&:focus {
				background-color: var(--bs-dropdown-link-hover-bg);
				border-bottom: 1px solid $utsa-orange;
			}
		}
	}
}

li.dropdown {
	&.dropdown-split {
		display: flex;
		align-items: baseline;
	}
}
