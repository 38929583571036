.global-searchbar {
	background-color: $utsa-blue;
	transition: transform 0.5s ease-out, opacity 1s ease-in, height 0.5s ease-out;
	transform: translate(0, -100%);
	float: left;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	height: 0;

	&.active {
		padding: 2rem 1rem;
		visibility: visible;
		transform: translate(0);
		float: none;
		height: auto;
		opacity: 1;
	}
}

//Toggle
.global-search {
	&.active {
		.fas.fa-search:before {
			content: "\f00d";
		}
	}
}

.searchbar {
	.searchfield {
		border: 1px solid $gray-01;
		border-radius: $btn-border-radius;
		width: 100%;
		padding: 0.5rem 1rem;
	}
}
