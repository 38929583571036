// + Search
.gsc-adBlock {
	display: none !important;
	visibility: hidden;
	height: 0;
}

.gsst_a {
	border: none;
}

div.gsc-control-cse,
div.gsc-control-cse .gsc-table-result {
	font-family: $body-font;
	font-size: 1rem;
	padding: 0;
}

div.gsc-control-cse {
	padding: 0;
	margin: 2rem 0;

	.gsc-thumbnail-inside,
	.gsc-webResult .gsc-url-top {
		padding: 0;
	}

	.gsc-search-button-v2 {
		padding: rem-calc(10) rem-calc(25);
	}

	.gsst_a .gscb_a {
		color: $blue-02;

		&:hover,
		&:focus {
			color: $orange-a11y;
		}
	}

	div.gsc-webResult {
		&.gsc-result {
			padding: 2.5rem 3rem;
			margin: 2rem 0;
			background-color: $gray-00;
			box-shadow: $dropdown-box-shadow;
			border-radius: $card-border-radius;
			border: none;

			a {
				&.gs-title {
					font-size: 1.25rem;
					font-family: $heading-font;
					color: $utsa-blue;
					text-decoration: none;
					font-weight: $med;
					margin: 0.5rem 0 0;
					display: inline-block;

					b {
						font-size: 1.25rem;
						font-weight: $bold;
						// color: $utsa-orange;
					}
				}
			}

			.gs-visibleUrl {
				color: $blue-02;
				font-size: 1rem;
				margin: 0 0 0.25rem 0;
			}

			.gs-snippet {
				color: $gray-05;
				font-size: 1rem;
				font-style: oblique;

				b {
					background-color: $white;
					color: $black;
				}
			}

			.gs-image-box {
				display: none;
			}
		}
	}
}

.gsc-results {
	div.gsc-cursor-box {
		margin: 2rem 0 1rem;
		.gsc-cursor {
			.gsc-cursor-page {
				background-color: $utsa-blue;
				color: $white;
				padding: 1rem 1.25rem;
				font-size: 1rem;
				font-family: $numbers;
				display: inline-block;

				&:hover,
				&:focus {
					background-color: $orange-a11y;
				}

				&.gsc-cursor-current-page {
					background-color: $blue-02;

					&:hover,
					&:focus {
						background-color: rgba($blue-02, 0.6);
					}
				}
			}
		}
	}
}
