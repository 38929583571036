.stat-row {
	display: flex;
	gap: 1rem 0;
}

.stat {
	border: 1px solid $gray-01;
	border-radius: $card-border-radius;
	text-align: center;
	max-height: 100%;
	height: auto;
	width: 100%;
	background-color: rgba($gray-00, 0.5);
	font-variant-numeric: lining-nums;
	display: flex;
	flex-flow: column;
	flex-wrap: wrap;
	padding: 3rem;
	margin: 0;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;

	// overide -- Equal Height
	min-height: 100%;
	// justify-content: flex-start;

	& > * {
		width: 100%;
	}

	img {
		height: rem-calc(85);
		width: auto;
	}

	.stat-figure {
		color: $orange-a11y;
		font-size: rem-calc(64);
		line-height: 120%;
		font-family: $numbers;
		font-weight: $black-weight;

		small {
			font-size: 2rem;
			vertical-align: middle;
		}
	}

	.stat-title {
		color: $blue-02;
		font-family: $heading-font;
		font-weight: $bold;
		font-size: rem-calc(20);
		margin: 0;
		line-height: 120%;
	}

	.stat-subtitle {
		font-family: $body-font;
		font-size: 1rem;
		font-weight: $bold;
		line-height: 165%;
	}

	p {
		font-size: rem-calc(15);
		font-family: $body-font;
		font-weight: $normal;
		line-height: 135%;
		margin: 0;
	}

	.source {
		color: $blue-02;
		font-family: $body-font;
		font-size: rem-calc(12);
		font-style: italic;
		font-weight: $normal;
		line-height: normal;

		a {
			font-weight: $bold;
			color: $blue-02;
		}
	}

	a.link-arrow {
		text-decoration: none;
		border-bottom: 1px solid $utsa-blue;
		max-width: max-content;
		margin: 0 auto;

		&:after {
			float: none;
			text-decoration: none;
		}
	}

	&-gray {
		background-color: $gray-00 !important;
		.stat-title,
		.stat-subtitle {
			color: $blue-02 !important;
		}
	}
}
