a {
	transition: all 0.3s ease;
	border-bottom: 1px solid $utsa-blue;
	text-decoration: none;
	font-weight: $bold;

	&:focus,
	&:hover {
		outline: 0px solid transparent;
		color: $blue-02;
		background-color: $gray-01;
		text-decoration: none;
		border-bottom: 1px solid $orange-a11y;
	}

	&.link-arrow {
		font-weight: $bold;
		text-decoration: underline;
		border-bottom: none;
		display: inline-block;
		transition: cubic-bezier(0.9, 0.1, 0.25, 0.5);
		position: relative;
		// white-space: nowrap;

		&:focus,
		&:hover {
			&:after {
				color: $utsa-blue;
				transform: translateX(0.25rem);
				padding: 0 0.5rem 0 0;
			}
		}

		&:after {
			content: "\f061";
			font-family: $fa;
			margin-left: rem-calc(8);
			display: inline-block;
			color: $utsa-orange;
		}
	}
}
