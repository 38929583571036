.icon-row {
	justify-content: center;
	margin: 4rem 0 0;

	.icon-col {
		text-align: center;
		margin-bottom: 4rem;

		img {
			margin-bottom: 1rem;
			width: 100%;
			max-width: rem-calc(65);
			height: auto;
		}

		.link-arrow {
			text-align: center;
			justify-content: center;
		}
	}
}
