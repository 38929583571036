.navbar {
	padding: 0.5rem 0 0;

	.navbar-nav {
		padding: 0;

		.nav-item {
			line-height: 1;
			margin: 0;
			white-space: nowrap;
			width: 100%;
			border-bottom: 0.25rem solid transparent;

			&:hover,
			&:focus {
				background-color: transparent;
				border-bottom-color: $utsa-orange;
				outline: none;
			}

			a {
				&.nav-link {
					color: $utsa-blue;
					font-weight: bold;
					padding: $nav-link-y-padding $nav-link-x-padding;
					border: 0;

					&.disabled {
						color: $gray-03;
						&:hover {
							cursor: not-allowed;
						}
					}

					&:focus,
					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}
}
